///
/// @file template_api/products/product_social_v1/src/product_social_v1.scss
///
/// \brief Styles for product product social - v1
///
///

@import '../../../../scss/theme-bootstrap';

.product__social-links {
  display: none;
  background: $white;
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
}

.product__social-container {
  cursor: pointer;
  position: absolute;
  &:hover {
    width: 300px;
    .product__social-links {
      display: block;
    }
  }
}

.product-social__icon {
  padding-right: 1em;
  .icon {
    width: 16px;
    height: 16px;
    &.icon--twitter {
      width: 20px;
      height: 20px;
    }
    &.icon--email {
      width: 19px;
      height: 19px;
    }
  }
  &--email {
    display: inline-block;
    margin-top: -7px;
    font-size: 19px;
  }
  &:hover,
  &:active {
    fill: $color-gray;
    text-decoration: none;
  }
}
